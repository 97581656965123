<template>
  <div>
    <div class="card">
      <div class="card-header card-header-primary card-header-icon">
        <div class="card-icon" style="background: #1565C0 !important;">
          <i class="material-icons">list</i>
        </div>
        <h4 class="card-title">
          <div class="row">
            <div class="col-md-4">
              Listado de Permisos
            </div>
            <div class="col-md-4">
                <v-select 
                    v-model="rol"
                    :items="roles"
                    item-text="nombre"
                    item-value="id"
                    label="Rol"
                    return-object
                    @change="loadPermisos"
                >
                </v-select>
            </div>
            <div class="col-md-4">
              <input type="checkbox" @change="setAll" v-model="all" /> Establecer todos los permisos a este rol
            </div>
          </div>
        </h4>
      </div>
      <div class="card-body">
        <div class="toolbar">
          <!--        Here you can write extra buttons/actions for the toolbar              -->
        </div>
        <div class="material-datatables">
            <v-data-table 
                :headers="permisosListHeaders"
                :items="permisosListBody"
                class="elevation-1 mx-0"
                label="Escriba Para buscar Permiso" 
                no-data-text="Listado de Permisos"
                fixed-header
                :search="search"
                :header-props="{ sortIcon: 'arrow_upward' }"
                :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'first_page',
                    lastIcon: 'last_page',
                    prevIcon: 'navigate_befores',
                    nextIcon: 'navigate_next'
                    //'items-per-page-options':[5,10,20],
                }"
            >
                <template v-slot:item.ver="{ item }">
                    <input type="checkbox" v-model="item.ver" :checked="item.ver == 1 ? 'checked' : ''" @change="save(item)" />
                </template>
                <template v-slot:item.grabar="{ item }">
                    <input type="checkbox" v-model="item.grabar" :checked="item.grabar == 1 ? 'checked' : ''" @change="save(item)" />
                </template>
                <template v-slot:item.editar="{ item }">
                    <input type="checkbox" v-model="item.editar" :checked="item.editar == 1 ? 'checked' : ''" @change="save(item)" />
                </template>
                <template v-slot:item.borrar="{ item }">
                    <input type="checkbox" v-model="item.borrar" :checked="item.borrar == 1 ? 'checked' : ''" @change="save(item)" />
                </template>
                <template v-slot:item.subir="{ item }">
                    <input type="checkbox" v-model="item.subir" :checked="item.subir == 1 ? 'checked' : ''" @change="save(item)" />
                </template>
                <template v-slot:item.todos="{ item }">
                    <input type="checkbox" v-model="item.todos" :checked="check(item) == 5 ? 'checked' : ''" @change="setMenuAll(item)" />
                </template>
            </v-data-table>
        </div>
      </div>
    </div>
    <v-snackbar
      v-model="snackbar"
      :timeout="5000"
      :color="color"
    >
      {{ validation_message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
    import {mapState, mapMutations} from 'vuex'
    export default {
        data: () => ({
            color: 'green',
            snackbar: false,
            errors: [],
            validation_message: '',
            success: null,
            search: null,
            permisoIndex: -1,
            rol: {},
            roles: [],
            all: false,
            permisosListHeaders: [
            {
                text: 'Rol',
                align: 'center',
                sortable: true,
                value: 'rol.nombre',
                class: "blue-grey darken-4 white-text"
            },
            {
                text: 'Menu',
                align: 'left',
                sortable: true,
                value: 'menu.nombre',
                class: "blue-grey darken-4 white-text"
            },
            {
                text: 'Lectura',
                align: 'center',
                sortable: false,
                value: 'ver',
                class: "blue-grey darken-4 white-text"
            },
            {
                text: 'Grabar',
                align: 'center',
                sortable: false,
                value: 'grabar',
                class: "blue-grey darken-4 white-text"
            },
            {
                text: 'Editar',
                align: 'center',
                sortable: false,
                value: 'editar',
                class: "blue-grey darken-4 white-text"
            },
            {
                text: 'Borrar',
                align: 'center',
                sortable: false,
                value: 'borrar',
                class: "blue-grey darken-4 white-text"
            },
            {
                text: 'Carga Masiva',
                align: 'center',
                sortable: false,
                value: 'subir',
                class: "blue-grey darken-4 white-text"
            },
            {
                text: 'Todos',
                align: 'center',
                sortable: false,
                value: 'todos',
                class: "blue-grey darken-4 white-text"
            },
        ],
        permisosListBody: [],
    }),
    mounted() {
        this.loadRoles();
    },
    methods:{
        check(item){
            return item.ver + item.grabar + item.editar + item.borrar + item.subir;
        },
        checkAll(){
            this.all = true;
            this.permisosListBody.forEach(p => {
                if(!p.ver || !p.grabar || !p.editar || !p.borrar || !p.subir){
                    this.all = false;
                }
            });
        },
        async loadPermisos(){
            let url = `${this.base_url}permisos/byrol/${this.rol.id}`;
            await this.axios.get(url, this.headers).then((response)=>{
                this.permisosListBody = response.data;
                this.checkAll();
            }).catch((error)=>{
                console.log(error);
            });
        },
        async loadRoles(){
            let url = `${this.base_url}roles`;
            await this.axios.get(url, this.headers).then((response)=>{
                this.roles = response.data;
                this.rol = this.roles[0];
                this.loadPermisos();
            }).catch((error)=>{
                console.log(error);
            });
        },
        async setMenuAll(item){
            let url  = `${this.base_url}permisos/menu/all`;
            let data = {roles_id: this.rol.id, menus_id: item.menu.id, valor: item.todos?1:0};
            await this.axios.post(url, data, this.headers).then((response)=>{
                console.log(response);
                this.loadPermisos();
                this.showSnackBar(false);
            }).catch((error)=>{
                this.errors = error.response.data;
                this.validation_message = this.errors.join();
                this.showSnackBar(true);
            });
        },
        async setAll(){
            let url  = `${this.base_url}permisos/all`;
            let data = {roles_id: this.rol.id, valor: this.all?1:0};
            await this.axios.post(url, data, this.headers).then((response)=>{
                console.log(response);
                this.loadPermisos();
                this.showSnackBar(false);
            }).catch((error)=>{
                this.errors = error.response.data;
                this.validation_message = this.errors.join();
                this.showSnackBar(true);
            });
        },
        async save(item){
            let url = `${this.base_url}permisos`;
            let data = {
                permiso_id: item.permiso_id,
                roles_id: item.rol.id,
                menus_id: item.menu.id,
                ver: item.ver? 1: 0,
                grabar: item.grabar? 1: 0,
                editar: item.editar? 1: 0,
                borrar: item.borrar? 1: 0,
                subir: item.subir? 1: 0,
            };
            await this.axios.post(url, data, this.headers).then((response)=>{
                console.log(response);
                this.loadPermisos();
                this.showSnackBar(false);
            }).catch((error)=>{
                this.errors = error.response.data;
                this.validation_message = this.errors.join();
                this.showSnackBar(true);
            });
        },
        showSnackBar(error){
            if(error){
                this.color = 'red';
            }else{
                this.color = 'green';
                this.validation_message = "Ejecutado exitosamente!";
            }
            this.snackbar = true;
        },
        ...mapMutations(['actualizarBaseUrl', 'mostrarLoading', 'ocultarLoading']),
    },
    computed:{
        ...mapState(['loading', 'base_url', 'headers']),
    },
    components:{
    }
  }
</script>